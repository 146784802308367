
module.exports =
  layout: 'promo'
  metaInfo:
    title: (if HM.is_russian then 'ХаниМани Помощь' else 'HoneyMoney Support Center') #70 characters
    meta: [
      {
        name: 'description',
        content: (if HM.is_russian then 'Подсказки, гиды и материалы по использованию ХаниМани' else 'Tutorials, guides and tips on using HoneyMoney')
      }
    ]
    components:
      quick_start: require('./docs/quick_start.vue').default
  computed:
    active_page: -> @$route.name
    displaying_page: -> !@active_page.includes 'docs_index'
  methods:
    closeModal: -> @$router.push("/#{HM.locale}/docs")
